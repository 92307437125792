import axios from 'axios'
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
const baseUrl = 'https://ejjgjjrys3.execute-api.us-east-1.amazonaws.com/Read?username='
const flipUrl = 'https://ejjgjjrys3.execute-api.us-east-1.amazonaws.com/check_flip?username='
async function getAll(username, time) {
    const res = await Auth.currentSession()
    const access = res.getAccessToken()
    console.log(access)
    console.log(access.getJwtToken())
    if (time === '') {
        const url = baseUrl + username + '&accessToken=' + access.getJwtToken()
        console.log(url)
        let response = await fetch(url, {
            headers: {
                'authorization': access.getJwtToken()
            }
        });
        if (response.ok) { // if HTTP-status is 200-299
            // get the response body (the method explained below)
            let json = await response.json();
            //console.log(json)
            return json
        }
    } else {
        const url = baseUrl + username + '&date=' + time + '&accessToken=' + access.getJwtToken()
        console.log(url)
        let response = await fetch(url, {
            headers: {
                'authorization': access.getJwtToken()
            }
        });
        if (response.ok) { // if HTTP-status is 200-299
            // get the response body (the method explained below)
            let json = await response.json();
            //console.log(json)
            return json
        }
    }
}
//const get = id => {
    //return axios.get(`${baseUrl}/${id}`)
//}

//const create = newObject => {
    //return axios.post(baseUrl, newObject)
//}
//const remove = id => {
    //console.log('removal start')
    //return axios.delete(`${baseUrl}/${id}`)
//}
//const update = (newObject, id) => {
    //return axios.put(`${baseUrl}/${id}`, newObject)
//}
async function getFli(username) {
    const url = flipUrl + username
    console.log(url);
    const response = await fetch(url);
    console.log("pre-json")
   // if (response.ok) { // if HTTP-status is 200-299
        // get the response body (the method explained below)
    let json = await response.json();
    console.log("json")
    console.log(json)
    return json
    //} else {
        //return { "body": false };
    //}
}
export default {
    getAll,
    getFli
}