import React from 'react'
import { Line, Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto'
import functions from './functions'
export const LineChart =({ chartData, ports, flip, all }) => {
    if (chartData == null) {
        return <div></div>
    }
    if (Object.entries(chartData).length === 0) {
        return <div></div>
    }
    console.log(chartData)
    let timestamps = chartData[0].map(entry => entry.timestamp)
    if (all) {
        let lows = [chartData[1].map(entry => entry.data[1]), chartData[0].map(entry => entry.data[1])]
        let highs = [chartData[1].map(entry => entry.data[0]), chartData[0].map(entry => entry.data[0])]
        let averages = [chartData[1].map(entry => entry.data[2]), chartData[0].map(entry => entry.data[2])]
        console.log("averages")
        console.log(averages)
        /*const response = await functions.getFlip(username)
        let flip = false
        if (response.hasOwnProperty("body")) {
            flip = response.body
        }*/
        if (flip) {
            lows = [chartData[0].map(entry => entry.data[1]), chartData[1].map(entry => entry.data[1])]
            highs = [chartData[0].map(entry => entry.data[0]), chartData[1].map(entry => entry.data[0])]
            averages = [chartData[0].map(entry => entry.data[2]), chartData[1].map(entry => entry.data[2])]
        }
        lows[0].reverse()
        lows[1].reverse()
        highs[0].reverse()
        highs[1].reverse()
        averages[0].reverse()
        averages[1].reverse()
        timestamps.reverse()//these functions put the x axis in the right order
        const data = {
            labels: timestamps,
            datasets: [
                {
                    id: 1,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    label: 'Before - Low',
                    data: lows[1]
                }, {
                    id: 2,
                    backgroundColor: 'rgba(255, 255, 132, 0.5)',
                    label: 'After - Low',
                    data: lows[0]
                }, {
                    id: 3,
                    backgroundColor: 'rgba(99, 132, 255, 0.5)',
                    label: 'Before - High',
                    data: highs[1]
                }, {
                    id: 4,
                    backgroundColor: 'rgba(255, 99, 255, 0.5)',
                    label: 'After - High',
                    data: highs[0]
                }, {
                    id: 5,
                    backgroundColor: 'rgba(50, 99, 132, 0.5)',
                    label: 'Before - Average',
                    data: averages[1]
                }, {
                    id: 1,
                    backgroundColor: 'rgba(255, 0, 0, 0.5)',
                    label: 'After - Average',
                    data: averages[0]
                }
            ]
        }; return (
            <div>
                <Line
                    data={data}
                    options={{
                        plugins: {
                            title: {
                                display: true,
                                text: "Ports " + ports[0] + " and " + ports[1] + ": Ammonia(PPM)"
                            },
                            legend: {
                                display: true,
                                position: "bottom"
                            }
                        },
                        maintainAspectRatio: false
                    }}
                    height={400}
                    width={600}
                />
            </div>
        );
    } else {
        const avg1 = chartData[0].map(entry => entry.data[2])
        const avg2 = chartData[1].map(entry => entry.data[2])
        let before_avg = avg1
        let after_avg = avg2
        if (flip) {
            after_avg = avg1
            before_avg = avg2
        }
        after_avg.reverse()
        before_avg.reverse()
        console.log("averages")
        console.log(avg1)
        console.log(avg2)
        const num1 = chartData[0].map(entry => entry.nums)
        const num2 = chartData[1].map(entry => entry.nums)
        console.log(chartData[0].map(entry => entry.nums))
        console.log(chartData[1].map(entry => entry.nums))
        const full1 = avg1.map((avg, index) => avg * num1[index])
        const full2 = avg2.map((avg, index) => avg * num2[index])
        console.log("start intermediate points")
        console.log(full1)
        console.log(full2)
        console.log("nums")
        console.log(num1)
        console.log(num2)
        const full_average = full1.map((full, index) => (full + full2[index]) / (num1[index] + num2[index]))
        console.log("full_average")
        console.log(full_average)
        //full_average.reverse()
        console.log(full_average)
        timestamps.reverse()
        const data = {
            labels: timestamps,
            datasets: [
                {
                    id: 2,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    label: 'before average',
                    data: before_avg
                },
                {
                    id: 3,
                    backgroundColor: 'rgba(50, 99, 132, 0.5)',
                    label: 'after average',
                    data: after_avg
                }, {
                    id: 1,
                    backgroundColor: 'rgba(0, 255, 0, 0.5)',
                    label: 'total average',
                    data: full_average
                }]
        };
        return (
            <div>
                <Line
                    data={data}
                    options={{
                        plugins: {
                            title: {
                                display: true,
                                text: "Ports " + ports[0] + " and " + ports[1] + ": Ammonia(PPM)"
                            },
                            legend: {
                                display: true,
                                position: "bottom"
                            }
                        },
                        maintainAspectRatio: false
                    }}
                    height={400}
                    width={600}
                />
            </div>
        );
    }
    
};
export const BarChart = ({ chartData, ports, flip }) => {
    if (chartData == null) {
        return <div></div>
    }
    if (Object.entries(chartData).length === 0) {
        return <div></div>
    }
    console.log(chartData)
    if (chartData[0] == null) {
        return <div></div>
    }
    if (chartData[1] == null) {
        return <div></div>
    }
    const sum = (total, num) => {
        return total + num
    }
    //const response = await functions.getFlip(username)
    /*let flip = false
    if (response.hasOwnProperty("body")) {
        flip = response.body
    }*/
    let rawData = [chartData[0].data, chartData[1].data]
    if (flip) {
        rawData = [chartData[1].data, chartData[0].data]
    }
    const labels = ['High', 'Low', 'Average']
    const average = ((chartData[0].nums * chartData[0].data[2]) + (chartData[1].nums * chartData[1].data[2])) / (chartData[0].nums + chartData[1].nums)
    const data = {
        labels,
        datasets: [
            {
                label: 'Before',
                data: rawData[0],
                backgroundColor: 'rgba(255, 99, 132, 0.5)'
            }, {
                label: 'After',
                data: rawData[1],
                backgroundColor: 'rgba(53, 162, 235, 0.5)'
            },
            {
                label: 'Combined',
                data: [0,0, average],
                backgroundColor: 'rgba(53, 235, 162, 0.5)'
            },
        ],
    }
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Ammonia(PPM) for ports ' + ports[0] + ' and ' + ports[1] + ' timestamp: ' + chartData[0].timestamp,
            },
        },
    };
    return (
        <div>
            <Bar
                data={data}
                options={options}
                height={400}
                width={600}
            />
        </div>
    );
}