import './App.css';
import { Button, Flex, Heading } from "@aws-amplify/ui-react";
import { useEffect, useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { LineChart, BarChart } from './components/Chart';
import SignIn from './components/SignIn'
import logo from './img/Logo.png'
import getData from './components/getData.js'
import functions from './components/functions.js'
// >>New - Configuring Auth Module
Amplify.configure(awsconfig);
Auth.configure(awsconfig);
function App() {
    const [data, setData] = useState([])
    const [days, setDays] = useState(0)
    const [start, setStart] = useState('recent')
    const [loggedIn, setLoggedIn] = useState(false)
    const [username, setUsername] = useState('')
    const [loading, setLoading] = useState(true)
    const [willFlip, setWillFlip] = useState(false)
    const [all, setAll] = useState(false)
    const assessLoggedInState = async() => {
        await Auth.currentAuthenticatedUser().then(sess => {
            console.log('logged in')
            console.log(sess)
            setUsername(sess.username)
            console.log(sess.username)
            setLoggedIn(true)
            initData(sess.username)
        }).catch(() => {
            console.log('not logged in')
            setLoggedIn(false)
        })
    }
    useEffect(() => {
        assessLoggedInState();
    },
        []
    )
    const initData = async (user, time) => {
        console.log(user)
        console.log(time)
        const date = new Date()
        const milliseconds = date.getTime()
        let millisecond_subtract = 0
        if (time == 'recent') {
            const response = await getData.GetData(user, '')
            setData(response)
            console.log(response)
        } else if (time == 'today'){
            millisecond_subtract = 1000 * 60 * 60 * 24
        } else if (time == 'week') {
            millisecond_subtract = 1000 * 60 * 60 * 24 * 7
        } else if (time == 'month') {
            millisecond_subtract = 1000 * 60 * 60 * 24 * 30
        } else if (time == '6 months') {
            millisecond_subtract = 1000 * 60 * 60 * 24 * 180
        } else if (time == 'year') {
            millisecond_subtract = 1000 * 60 * 60 * 24 * 365
        } else if (time == 'custom') {
            millisecond_subtract = 1000 * 60 * 60 * 24 * days
        } else {
            const response = await getData.GetData(user, '')
            setData(response)
            const flipper = await functions.getFli(user)
            let flip = false
            if (flipper.hasOwnProperty("body")) {
                flip = flipper.body
            }
            setWillFlip(flip)
            return
        }
        if (time != 'recent') {
                const newDay = new Date(milliseconds - millisecond_subtract)
                let day = newDay.getDate();
                let month = newDay.getMonth() + 1;
                let year = newDay.getFullYear();
                let currentDate = `${year}-${month}-${day}`;
                if (month < 10) {
                    if (day < 10) {
                        currentDate = `${year}-0${month}-0${day}`
                    } else {
                        currentDate = `${year}-0${month}-${day}`
                    }
                } else if (day < 10) {
                    currentDate = `${year}-${month}-0${day}`
                }
                console.log(currentDate)
                const response = await getData.GetData(user, currentDate)
                setData(response)
                console.log(response)
        }
        console.log("test")
        console.log(user)
        const flipper = await functions.getFli(user)
        console.log(flipper)
        let flip = false
        if (flipper.hasOwnProperty("body")) {
            flip = flipper.body
        }
        setWillFlip(flip)
    }
    useEffect(() => {
        initData();
    }, [username, start])
    const loader = () => {
        if (data.length > 0) {
            console.log(data)
            console.log(data[1][0])
            setLoading(false)
            }
        }
    useEffect(() => {
        loader();
    }, [data])
    const handleFilterChange = (event) => {
        setDays(event.target.value)
    }
    const toggleAll = () => {
        setAll(!all)
    }
    const signOut = () => {
        console.log('...signing out ....');
        Auth.signOut();
        setLoggedIn(false)
    }
    const barDisplay = data.length > 0 ?(<div>
        {[...Array(data[0].length).keys()].map(index => (<>
            <h style={{ fontSize: "3rem" }}> Avg: {parseInt(((data[0][index].nums * data[0][index].data[2]) + (data[1][index].nums * data[1][index].data[2])) / (data[0][index].nums + data[1][index].nums))} ppm</h>
            <BarChart chartData={[data[0][index], data[1][index]]} ports={['1', '2']} flip={willFlip} key={`1, 2, ${index}`} />
            <h style={{ fontSize: "3rem" }}> Avg: {parseInt(((data[2][index].nums * data[2][index].data[2]) + (data[3][index].nums * data[3][index].data[2])) / (data[2][index].nums + data[3][index].nums))} ppm</h>
            <BarChart chartData={[data[2][index], data[3][index]]} ports={['3', '4']} flip={willFlip} key={`3, 4, ${index}`} />
            <h style={{ fontSize: "3rem" }}> Avg: {parseInt(((data[4][index].nums * data[4][index].data[2]) + (data[5][index].nums * data[5][index].data[2])) / (data[4][index].nums + data[5][index].nums))} ppm</h>
            <BarChart chartData={[data[4][index], data[5][index]]} ports={['5', '6']} flip={willFlip} key={`5, 6, ${index}`} />
            <h style={{ fontSize: "3rem" }}> Avg: {parseInt(((data[6][index].nums * data[6][index].data[2]) + (data[7][index].nums * data[7][index].data[2])) / (data[6][index].nums + data[7][index].nums))} ppm</h>
            <BarChart chartData={[data[6][index], data[7][index]]} ports={['7', '8']} flip={willFlip} key={`7, 8, ${index}`} />
            </>))}
    </div>) : (<></>)
    const lineDisplay = (<div>
        <Button onClick={toggleAll}>Toggle data</Button>
        <LineChart chartData={[data[0], data[1]]} ports={['1', '2']} flip={willFlip} all={all}/>
        <LineChart chartData={[data[2], data[3]]} ports={['3', '4']} flip={willFlip} all={all}/>
        <LineChart chartData={[data[4], data[5]]} ports={['5', '6']} flip={willFlip} all={all}/>
        <LineChart chartData={[data[6], data[7]]} ports={['7', '8']} flip={willFlip} all={all}/>
    </div>)
    const appData = loading ? <><span>Loading, please wait</span>
        <Button onClick={signOut}>Sign Out</Button></>
        : (<><Flex direction={"column"}>
                <Flex justifyContent={'space-between'}>
                    <Heading level={1}>{username}</Heading>
            <Button onClick={signOut}>Sign Out</Button>
            </Flex>
        </Flex>
            <Button onClick={() => initData(username, 'recent')}>Most Recent</Button>
            <Button onClick={() => initData(username, 'today')}>Today</Button>
            <Button onClick={() => initData(username, 'week')}>A Week</Button>
            <Button onClick={() => initData(username, 'month')}>A Month</Button>
            <Button onClick={() => initData(username, '6 months')}>6 Months</Button>
            <Button onClick={() => initData(username, 'year')}>A year</Button>
            <Button onClick={() => initData(username, 'custom')}>Custom Days</Button>
            <input value={days} onChange={handleFilterChange} />
            <div className="App">
                {data[0].length < 3 ? barDisplay : lineDisplay}
        </div> </>)
    const signInData = <div>
        <h1>
            <center>Welcome To TOMIGUNN AMOS!
                </center>
        </h1>
        <SignIn onSignIn={assessLoggedInState} />
        </div>
    return (
        <div>
            <center>
                <img src={logo} alt="tomigunn logo" height= '100' width = '100'/>
                </center>
                {loggedIn ? appData : signInData}
            </div>

        );
    }
    export default App;