import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Paper, Button, TextField } from '@material-ui/core';
//import { Link, useNavigate } from 'react-router-dom';
import '../App.css'
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const SignIn = ({ onSignIn }) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [code, setCode] = useState('')
    const [new_password, setNew_Password] = useState('')
    //const [confirmPassword, setConfirmPassword] = useState('')
    const [forgotPassword, setForgotPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [signingUp, setSigningUp] = useState(false)
    //const [newUsername, setNewUsername] = useState('')
    const [email, setEmail] = useState('')
    const [confirm, setConfirm] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    //const navigate = useNavigate()
    const signIn = async () => {
        try {
            console.log(username)
            console.log(password)
            const user = await Auth.signIn(username.toLowerCase(), password)
            //navigate('/')
            setErrorMessage('')
            onSignIn()
        } catch (error) {
            console.log('error signing in', error)
            setErrorMessage(error.message)
        }
    }
    const back = () => {
        setSigningUp(false)
        setForgotPassword(false)
        setShowPassword(false)
        setErrorMessage('')
        //setNewUsername('')
        setCode('')
        setEmail('')
        setNew_Password('')
        setPassword('')
    }
    const forgot = async () => {
        await Auth.forgotPassword(username)
            .then(data => {
                console.log(data)
                setForgotPassword(true)
                setErrorMessage('')
            })
            .catch(err => {
                console.log(err)
                setErrorMessage(err.message)
            })
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const forgotSubmit = () => {
        //if (new_password != confirmPassword) {
        //    setErrorMessage('Passwords do not match')
        //} else {
            Auth.forgotPasswordSubmit(username, code, new_password)
                .then(data => {
                    console.log(data)
                    setForgotPassword(false)
                    setErrorMessage('')
                })
                .catch(err => {
                    console.log(err)
                    setErrorMessage(err.message)
                });
       // }
    }
    const createAccount = async() => {
        try {
            console.log(username)
            await Auth.signUp({ username, password, attributes: { email } })
            console.log('partway')
            setConfirm(true)
        } catch (error) {
            console.log('error signing up', error)
            setErrorMessage(error.message)
        }
    }
    const confirmSignUp = async () => {
        try {
            console.log(code)
            await Auth.confirmSignUp(username, code)
            console.log('successful sign up')
            signIn()
        } catch (error) {
            console.log('error signing up', error)
            setErrorMessage(error.message)
        }
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const confirm_display = (<div>
        <center>
        <span>A verification code has been sent to your email, please put it here</span></center>
        <center><TextField
            id='code'
            label='code'
            value={code}
            onChange={e => setCode(e.target.value)}
        /></center>
        <center>
            <Button id='confirm' color='primary' onClick={confirmSignUp}>
                confirm account creation
            </Button>
            <Button id='back to sign in' color='primary' onClick={back}>
                Sign in to existing account
            </Button>
        </center>

    </div>)
    const signup_display = (confirm ? confirm_display :(<div>
        <center><TextField
            id='new_username'
            label='Username'
            value={username}
            onChange={e => setUsername(e.target.value)}
        /></center>
        <center><TextField
            id='new_password'
            type={showPassword ? 'text' : 'password'}
            label='Password'
            value={password}
            onChange={e => setPassword(e.target.value)}
        />
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton></center>
        <center><TextField
            id='email'
            label='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
        /></center>
        <center>
            <Button id='createAccount' color='primary' onClick={createAccount}>
                Create Account
            </Button>
            <Button id='back to sign in' color='primary' onClick={back}>
                Sign in to existing account
            </Button>
        </center>
    </div>))
    const create = () => {
        setErrorMessage('')
        setSigningUp(true)
        setShowPassword(false)
    }
    const login = (signingUp ? signup_display : (<div className='login'>
        <center><TextField
            id='username'
            label='Username'
            value={username}
            onChange={e => setUsername(e.target.value)}
        /></center>
        <center><TextField
            id='password'
            label='Password'
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
            onSubmit={signIn}
        />
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton></center>
        <center>
            <Button id='signinButton' color='primary' onClick={signIn}>
                Sign in
            </Button>
            <Button id='forgotButton' color='primary' onClick={forgot}>
                Forgot your password?
            </Button>
        </center>
        <center>
            <Button id='createButton' color='primary' onClick={create}>
                create new account
            </Button>
        </center>
    </div>))
    const forget_display = (<div>
        <center><p>
            A verification code has been sent to your email address, please input the code and your new password
        </p></center>
        <center><TextField
            id='code'
            label='Code'
            value={code}
            onChange={e => setCode(e.target.value)}
        /></center>
        <center><TextField
            id='new_password'
            label='New Password'
            value={new_password}
            onChange={e => setNew_Password(e.target.value)}
        />
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton></center>
        <center>
            <Button id='changePassword' color='primary' onClick={forgotSubmit}>
                Change Password
            </Button>
            <Button id='back to sign in' color='primary' onClick={back}>
                Back to sign in
            </Button>
        </center>
        </div>
    )
    
    return (
        <div>
            { forgotPassword ? forget_display : login}
            <center><p id='error-message'>{errorMessage}</p></center>
        </div>
        
    )
}
export default SignIn