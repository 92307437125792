import functions from './functions'
import { useEffect, useState } from 'react';
function GetData(username, time) {
    const initData = async () => {
        //console.log(username)
        const response = await functions.getAll(username,time)
        console.log(response)
        return setPorts(response)
    }
    //useEffect(() => {
    //    initData();
    //}, [])
    //TODO: Allow multiple batches
    const setPorts = (response) => {
        const port1 = []
        const port2 = []
        const port3 = []
        const port4 = []
        const port5 = []
        const port6 = []
        const port7 = []
        const port8 = []
        const dataset = response.body
        for (let i = 0; i < dataset.length; i++) {
            let data = dataset[i]
            console.log(data)
            let timestamp = data.Timing.substr(29, 14)
            port1.push({
                nums: data.nums[0],
                data: data.values[0],
                timestamp: timestamp
            })
            port2.push({
                nums: data.nums[1],
                data: data.values[1],
                timestamp: timestamp
            })
            port3.push({
                nums: data.nums[2],
                data: data.values[2],
                timestamp: timestamp
            })
            port4.push({
                nums: data.nums[3],
                data: data.values[3],
                timestamp: timestamp
            })
            port5.push({
                nums: data.nums[4],
                data: data.values[4],
                timestamp: timestamp
            })
            port6.push({
                nums: data.nums[5],
                data: data.values[5],
                timestamp: timestamp
            })
            port7.push({
                nums: data.nums[6],
                data: data.values[6],
                timestamp: timestamp
            })
            port8.push({
                nums: data.nums[7],
                data: data.values[7],
                timestamp: timestamp
            })
        }
        return ([port1, port2, port3, port4, port5, port6, port7, port8])
    }
    return initData()
        /*const arr1 = []
        const arr2 = []
        const arr3 = []
        const arr4 = []
        const arr5 = []
        const arr6 = []
        const arr7 = []
        const arr8 = []
        for (let i = 0; i < response.body.length; i++) {
            if ('port' in response.body[i]) {
                switch (response.body[i].port) {
                    case 1: arr1.push(response.body[i]);
                        break;
                    case 2: arr2.push(response.body[i]);
                        break;
                    case 3: arr3.push(response.body[i]);
                        break;
                    case 4: arr4.push(response.body[i]);
                        break;
                    case 5: arr5.push(response.body[i]);
                        break;
                    case 6: arr6.push(response.body[i]);
                        break;
                    case 7: arr7.push(response.body[i]);
                        break;
                    case 8: arr8.push(response.body[i]);
                        break;
                }
            }
        }
        //console.log(arr1)
        let avg1 = []
        let times1 = arr1.map((x) => x.timestamp)
        if (arr1.length < 3) {
            avg1 = arr1
        } else {
            for (let i = 0; i < arr1.length - 2; i++) {
                avg1.push((arr1[i].value + arr1[i + 1].value + arr1[i + 2].value) / 3)
            }
            times1.pop()
            times1.shift()
        }
        const port1 = ({
            labels: times1,
            datasets: [
                {
                    label: "Ammonia(PPM)",
                    data: avg1,
                    backgroundColor: [
                        "#ffbb11",
                        "#ecf0f1",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ]
                }
            ]
        })
        //console.log(arr2)
        let avg2 = []
        let times2 = arr2.map((x) => x.timestamp)
        if (arr2.length < 3) {
            avg2 = arr2
        } else {
            for (let i = 0; i < arr2.length - 2; i++) {
                avg2.push((arr2[i].value + arr2[i + 1].value + arr2[i + 2].value) / 3)
            }
            times2.pop()
            times2.shift()
        }
        const port2 = ({
            labels: times2,
            datasets: [
                {
                    label: "Ammonia(PPM)",
                    data: avg2,
                    backgroundColor: [
                        "#ffbb11",
                        "#ecf0f1",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ]
                }
            ]
        })
        let avg3 = []
        let times3 = arr3.map((x) => x.timestamp)
        if (arr3.length < 3) {
            avg3 = arr3
        } else {
            for (let i = 0; i < arr3.length - 2; i++) {
                avg3.push((arr3[i].value + arr3[i + 1].value + arr3[i + 2].value) / 3)
            }
            times3.pop()
            times3.shift()
        }
        const port3 = ({
            labels: times3,
            datasets: [
                {
                    label: "Ammonia(PPM)",
                    data: avg3,
                    backgroundColor: [
                        "#ffbb11",
                        "#ecf0f1",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ]
                }
            ]
        })
        let avg4 = []
        let times4 = arr4.map((x) => x.timestamp)
        if (arr4.length < 3) {
            avg4 = arr4
        } else {
            for (let i = 0; i < arr4.length - 2; i++) {
                avg4.push((arr4[i].value + arr4[i + 1].value + arr4[i + 2].value) / 3)
            }
            times4.pop()
            times4.shift()
        }
        const port4 = ({
            labels: times4,
            datasets: [
                {
                    label: "Ammonia(PPM)",
                    data: avg4,
                    backgroundColor: [
                        "#ffbb11",
                        "#ecf0f1",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ]
                }
            ]
        })
        let avg5 = []
        let times5 = arr5.map((x) => x.timestamp)
        if (arr5.length < 3) {
            avg5 = arr5
        } else {
            for (let i = 0; i < arr5.length - 2; i++) {
                avg5.push((arr5[i].value + arr5[i + 1].value + arr5[i + 2].value) / 3)
            }
            times5.pop()
            times5.shift()
        }
        const port5 = ({
            labels: times5,
            datasets: [
                {
                    label: "Ammonia(PPM)",
                    data: avg5,
                    backgroundColor: [
                        "#ffbb11",
                        "#ecf0f1",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ]
                }
            ]
        })
        let avg6 = []
        let times6 = arr6.map((x) => x.timestamp)
        if (arr6.length < 3) {
            avg6 = arr6
        } else {
            for (let i = 0; i < arr6.length - 2; i++) {
                avg6.push((arr6[i].value + arr6[i + 1].value + arr6[i + 2].value) / 3)
            }
            times6.pop()
            times6.shift()
        }
        const port6 = ({
            labels: times6,
            datasets: [
                {
                    label: "Ammonia(PPM)",
                    data: avg6,
                    backgroundColor: [
                        "#ffbb11",
                        "#ecf0f1",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ]
                }
            ]
        })
        let avg7 = []
        let times7 = arr7.map((x) => x.timestamp)
        if (arr7.length < 3) {
            avg7 = arr7
        } else {
            for (let i = 0; i < arr7.length - 2; i++) {
                avg7.push((arr7[i].value + arr7[i + 1].value + arr7[i + 2].value) / 3)
            }
            times7.pop()
            times7.shift()
        }
        const port7 = ({
            labels: times7,
            datasets: [
                {
                    label: "Ammonia(PPM)",
                    data: avg7,
                    backgroundColor: [
                        "#ffbb11",
                        "#ecf0f1",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ]
                }
            ]
        })
        let avg8 = []
        let times8 = arr8.map((x) => x.timestamp)
        if (arr8.length < 3) {
            avg8 = arr8
        } else {
            for (let i = 0; i < arr8.length - 2; i++) {
                avg8.push((arr8[i].value + arr8[i + 1].value + arr8[i + 2].value) / 3)
            }
            times8.pop()
            times8.shift()
        }
        const port8 = ({
            labels: times8,
            datasets: [
                {
                    label: "Ammonia(PPM)",
                    data: avg8,
                    backgroundColor: [
                        "#ffbb11",
                        "#ecf0f1",
                        "#50AF95",
                        "#f3ba2f",
                        "#2a71d0"
                    ]
                }
            ]
        })*/
}

export default {
 GetData
}